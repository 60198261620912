<i18n src="@/locales/all_on_four.json" />
<template>
  <div class="all-on-four">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <b-message
          type="is-info"
        >
          <div v-html="$t('message_1_1')" />
        </b-message>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <p>
          {{ $t('article_p2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_4') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_3')">
          <p>
            {{ $t('collapse_p3') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_4')">
            {{ $t('more_info_4') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'AllOn4',
    components: {
      ImanMoreInfo
    },
    mixins: [metaMixin],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-implant/trad-implant/all-on-four/all-on-4-c-est-quoi.jpg',
            position: 'right',
            alt: 'Définition d\'une prothèse fixe sur implants dentaires'
          },
          {
            path: 'dental-implant/trad-implant/all-on-four/basal-implant-vs-all-on-4.jpg',
            position: 'right',
            alt: 'Implant basal vs all on 4'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.1.3.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/all-on-four/H1-prothese-fixe-all-on-4-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/all-on-four/H1-prothese-fixe-all-on-4-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/trad-implant/all-on-four/H1-prothese-fixe-all-on-4-m.jpg");
    }
  }
</style>
